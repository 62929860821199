$(document).ready(function () {
    // Move current language from dropdown list
    /*$( ".lang-switcher" ).each(function() {
      var list = $(this).find( ".list .current" );
      $(list).appendTo($(this).find('.selected'));
    });*/
  
    // Show / Hide  list of languages
    $(".lang-switcher").on('click', function (event) {
      $(this).find('.list').fadeToggle('fast');
      $(this).toggleClass('lang-switcher--opened');
    });
  });
  
  // Close when clicking outside
  $(document).bind('click', function(e){
      if (! $(e.target).parents().hasClass("lang-switcher")) {
      $(".lang-switcher .list").fadeOut();
      $(".lang-switcher").removeClass('lang-switcher--opened');
    }
  });
  