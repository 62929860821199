$(document).ready(function () {
    $('.faq .faq__question').click(function(){
      /*var parent = $(this).parent();
      var faq = parent.attr('data-faq');
      */

      // Highlight question

      /*
      var parent = $(this).parent();
      var content = $(this).attr('data-question');
      var content_parent = $('.faq__answear[data-question="'+ content +'"]').parent();

      // Highlight question
      $(parent).find('.faq__question').removeClass('active');
      $(this).addClass('active');
      // Show content
      $(content_parent).find('.faq__answear').slideUp('fast').removeClass('active');
      $('.faq__answear[data-question="'+ content +'"]').slideDown('fast').addClass('active');
      */
    });
  
});

$(document).ready(function(){
  $('.faq > .single > .faq__answear').hide();

  $('.faq > .single.active > .faq__answear').slideDown();

  $('.faq > .single').click(function() {
    var parent = $(this).parent();
    var faqid = $(parent).attr('data-faq');

    if ($(this).hasClass("active")) {
      $(this).removeClass("active").find(".faq__answear").slideUp();
    } else {

      if ($(parent).hasClass("accordion")) {
        $('.faq[data-faq="'+ faqid +'"] > .single.active .faq__answear').slideUp();
        $('.faq[data-faq="'+ faqid +'"] > .single.active').removeClass("active");
        $(this).addClass("active").find(".faq__answear").slideDown();  
      } else {
        $(this).addClass("active").find(".faq__answear").slideDown();
      }
  
    }
    return false;
  });
  
});